/* eslint-disable */
import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import store from '@/store/index.js'
import { loadLanguageAsync } from '../translations'
import { appSettings } from '@/settings'
import { authGuard } from '@auth0/auth0-vue'
import { auth0 } from '../auth/auth0'
import { unref } from 'vue'

// [a-z]{2} --> 2 times language code, eg. en
// (?:-[a-zA-Z]{2})? --> optional group -> (?: xxx)?
//                        of upper/lowercase characters [a-zA-Z]{2}
// :lang(xxxx)? --> capture as lang parameter (which is optional -> ? at end)
// UPDATE: vue router is special with regex, so what we did:
// it is either 2 language code: [a-z]{2}
// or (|) it is languagecode-coutrycode: [a-z]{2}-[a-zA-Z]{2}
const languageCodeRegex = '/:lang([a-z]{2}|[a-z]{2}-[a-zA-Z]{2})?'

const routes = [
  {
    path: languageCodeRegex + '/',
    name: 'home',
    component: Home,
  },
  {
    path: languageCodeRegex + '/LogoutCallback',
    name: 'logoutCallback',
    component: () => import(/* webpackChunkName: "view-logout" */ "@/views/LogoutCallback.vue"),
  },
  {
    path: languageCodeRegex + '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "view-logout" */ "@/views/Logout.vue"),
  },
  {
    path: languageCodeRegex + '/register/recipient',
    name: 'reg-recipient',
    component: () => import(/* webpackChunkName: "view-RegisterRecipient" */ "@/views/RegisterRecipient.vue")
  },
  {
    path: languageCodeRegex + '/services/pcb',
    name: 'pcb',
    component: () => import(/* webpackChunkName: "view-PcbUnavailable" */ "@/views/PcbUnavailable.vue")
  },
  {
    path: languageCodeRegex + '/change-email',
    name: 'change-email',
    component: () => import(/* webpackChunkName: "view-ChangeEmail" */ "@/views/ChangeEmail.vue"),
    meta: {
      requiresProfile: true
    },
    beforeEnter: authGuard
  },
  {
    path: languageCodeRegex + '/register/caregiver',
    name: 'reg-caregiver',
    component: () => import(/* webpackChunkName: "view-RegisterCaregiver" */ "@/views/RegisterCaregiver.vue")
  },
  {
    path: languageCodeRegex + '/register/professional',
    name: 'reg-professional',
    component: () => import(/* webpackChunkName: "view-RegisterProfessional" */ "@/views/RegisterProfessional.vue")
  },
  {
    path: languageCodeRegex + '/register/basic',
    name: 'reg-basic',
    component: () => import(/* webpackChunkName: "view-RegisterBasic" */ "@/views/RegisterBasic.vue")
  },
  {
    path: languageCodeRegex + '/register/complete',
    meta: {
      requiresProfile: true
    },
    name: 'reg-complete',
    component: () => import(/* webpackChunkName: "view-PortalProfileComplete" */ "@/views/PortalProfileComplete.vue"),
    beforeEnter: authGuard
  },
  {
    path: languageCodeRegex + '/info/emailverified',
    name: 'email-verified',
    component: () => import(/* webpackChunkName: "view-EmailVerified" */ "@/views/EmailVerified.vue")
  },
  {
    path: languageCodeRegex + '/info/onboarding',
    name: 'onboarding',
    component: () => import(/* webpackChunkName: "view-Onboarding" */ "@/views/Onboarding.vue")
  },
  {
    path: languageCodeRegex + '/info/emailchangeresult',
    name: 'emailchangeresult',
    component: () => import(/* webpackChunkName: "view-EmailChangeResult" */ "@/views/EmailChangeResult.vue")
  },
  {
    path: languageCodeRegex + '/callback',
    component: () => import(/* webpackChunkName: "view-Callback" */ "@/views/Callback.vue"),
    name: 'callback',
    beforeEnter: authGuard
  },
  {
    path: languageCodeRegex + '/portal',
    meta: {
      requiresProfile: true
    },
    // component: lazyLoad('Portal'),
    component: () => import(/* webpackChunkName: "view-Portal" */ "@/views/Portal.vue"),
    children: [
      {
        path: '',
        name: 'dashboard',
        meta: {
          requiresProfile: true
        },
        component: () => import(/* webpackChunkName: "view-PortalDashboard" */ "@/views/PortalDashboard.vue"),
        // component: lazyLoad('PortalDashboard'),
        beforeEnter: authGuard,
      },
      {
        path: 'services/audiokey3',
        name: 'services-audiokey3',
        component: () => import(/* webpackChunkName: "view-AudioKey3 */ "@/views/AudioKey3.vue"),
        beforeEnter: authGuard,
      },
      {
        path: 'services/audiokey2',
        name: 'services-audiokey2',
        component: () => import(/* webpackChunkName: "view-AudioKey2 */ "@/views/AudioKey2.vue"),
        beforeEnter: authGuard,
      },
      {
        path: 'services/audiokey1',
        name: 'services-audiokey1',
        component: () => import(/* webpackChunkName: "view-AudioKey1" */ "@/views/AudioKey1.vue"),
        beforeEnter: authGuard,
      },
      {
        path: 'services/redi',
        name: 'services-redi',
        component: () => import(/* webpackChunkName: "view-Redi" */ "@/views/Redi.vue"),
        beforeEnter: authGuard,
        meta: {
          breadcrumbs: [{
            to: 'dashboard',
            text: 'dashboard'
          }]
        }
      },
      {
        path: 'profile',
        name: 'profile',
        meta: {
          requiresProfile: true
        },
        component: () => import(/* webpackChunkName: "view-PortalProfile" */ "@/views/PortalProfile.vue"),
        beforeEnter: authGuard,
        meta: {
          breadcrumbs: [{
            to: 'dashboard',
            text: 'dashboard'
          }]
        }
      },
      {
        path: 'profile/edit',
        name: 'profile-edit',
        meta: {
          requiresProfile: true
        },
        component: () => import(/* webpackChunkName: "view-PortalProfileEdit" */ "@/views/PortalProfileEdit.vue"),
        beforeEnter: authGuard,
        meta: {
          breadcrumbs: [
            {
              to: 'dashboard',
              text: 'dashboard'
            },
            {
              to: 'profile',
              text: 'profile'
            }
          ]
        }
      },
      {
        path: 'services/hearcare',
        name: 'services-hearcare',
        component: () => import(/* webpackChunkName: "view-HearCare" */ "@/views/HearCare.vue"),
        beforeEnter: authGuard
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'home' }
  }
]

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 500)
    })
  }
})

// loads language files based on an optional param
async function loadLanguageGuard (to, from) {
  let lang = getLangOrDefault(to)
  await loadLanguageAsync(lang)
}

async function checkLoggedIn(to, from){
  try {
    await auth0.getAccessTokenSilently()
  } catch (error) {}
}

router.beforeEach(loadLanguageGuard)
router.beforeEach(checkLoggedIn);

let removeMaintenanceGuard = router.beforeResolve((to, from) => {
  // in maintenance mode always go to home
  if(appSettings.maintenanceMode === true){
    if ( to.name !== 'home') {
      return {name: 'home'}
    }
  }
  if(appSettings.maintenanceMode === false) {
    removeMaintenanceGuard()
  }
})

// if user has email_verified explicitly set to false -> redirect to dashboard.
// This navigation guard has to run at beforeResolve, because we only care about logged in users
async function emailGuard(to, from) {
  const ignoredDestinations = ['reg-complete', 'dashboard', 'change-email', 'home', 'callback', 'portal', 'logout']
  if (ignoredDestinations.includes(to.name) || !unref(auth0.isAuthenticated)) {
    return
  }

  const email = auth0.user.value.email
  const isMedelEmployee = email?.includes('@medel.') && !email?.includes('@medel.fake')
  const isEmailVerified = unref(auth0?.user.value.email_verified)

  if(!isMedelEmployee && isEmailVerified === false){
    return { name: 'dashboard' }
  }

  if (isEmailVerified === true || isMedelEmployee) {
    removeEmailVerifiedGuard()
  }
}

let removeEmailVerifiedGuard = router.beforeResolve(emailGuard)

function getLangOrDefault(to) {
  // if the language is not in the url parameter, use the browser language.
  // if nothing can be detected, use english as default.
  let lang = to.params.lang;
  if(!lang && store.getters['user/language']) {
    lang = store.getters['user/language']
  }
  if (!lang) {
    lang = navigator.language || navigator.userLanguage || 'en'
  }
  return lang
}

export default router
